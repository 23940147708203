import { CredentialResponse, GoogleLogin, NonOAuthError, TokenResponse, UseGoogleLoginOptionsImplicitFlow } from "@react-oauth/google"
import { pipe } from "fp-ts/lib/function.js"
import * as O from "fp-ts/lib/Option.js"
import * as E from "fp-ts/lib/Either.js"
import {
  ComponentPropsWithoutRef,
  Dispatch,
  SetStateAction,
  useContext,
} from "react"
import { DidLogoutCredentials } from "./App"
import { ErrorContext } from "./Error"
import img1 from "./images/hans-eiskonen-wn57cSQ7VzI-unsplash.jpg"
export type EnterProps = {
  readonly didLogout: boolean
  readonly setCredentials: Dispatch<SetStateAction<DidLogoutCredentials>>
}
import { useGoogleLogin, hasGrantedAllScopesGoogle } from '@react-oauth/google';

const randomImage = () => {
  const images = [img1]
  const idx = Math.floor(Math.random() * images.length)
  return images[idx]
}
const scope = "https://www.googleapis.com/auth/datastore"

const checkScope = (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>): E.Either<Error, string> =>
  hasGrantedAllScopesGoogle(tokenResponse, scope) ?
    E.right(tokenResponse.access_token) :
    E.left(new Error(`Missing necessary sope ${scope}.`))

const googleLoginSuccess =
  (setCredentials: Dispatch<SetStateAction<DidLogoutCredentials>>,
    addError: (error: Error) => void) =>
    (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) =>
      pipe(
        checkScope(tokenResponse),
        E.mapLeft(addError),
        E.map(c => [false, O.fromNullable(c)] as DidLogoutCredentials),
        E.map(setCredentials)
      )

const googleLoginFailure = (
  setCredentials: Dispatch<SetStateAction<DidLogoutCredentials>>,
  addError: (error: Error) => void) =>
  () =>
    pipe(
      new Error("Login failed"),
      addError,
      () => setCredentials([false, O.none])
    )



const GoogleLoginButton: React.FC<ComponentPropsWithoutRef<"button">> =
  ({ ...props }) => (
    <button className="bg-white px-4 py-2 border flex gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150 w-96" {...props} >
      <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
      <span>Login with Google</span>
    </button>
  )

export const Enter: React.FC<EnterProps & ComponentPropsWithoutRef<"div">> = ({
  didLogout,
  setCredentials,
  ...props
}) => {
  const { addError } = useContext(ErrorContext)
  const login = useGoogleLogin({
    scope,
    hosted_domain: "mollyware.se",
    prompt: "consent",
    onSuccess: googleLoginSuccess(setCredentials, addError),
    onError: googleLoginFailure(setCredentials, addError),
    onNonOAuthError: (nonOAuthError: NonOAuthError) => addError(new Error(`Could not complete login prompt: ${nonOAuthError.type}`))

  } as UseGoogleLoginOptionsImplicitFlow)
  return (
    <>
      <div
        className="flex h-screen min-h-full flex-1 flex-row bg-slate-800"
        {...props}
      >
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <h1 className="text-center font-serif text-3xl font-bold tracking-widest text-zinc-100 drop-shadow-lg">
              Fin trader
            </h1>
            <div className="mt-4 mb-16">
              <GoogleLoginButton onClick={() => login()} />
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={randomImage()}
            alt=""
          />
        </div>
      </div>
    </>
  )
}
