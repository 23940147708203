import { ComponentPropsWithoutRef, FormEvent, useContext, useEffect, useState } from "react"
import { pipe } from "fp-ts/lib/function"
import * as O from "fp-ts/lib/Option.js"
import * as TE from "fp-ts/lib/TaskEither.js"
import * as R from "fp-ts/lib/Record.js"
import * as AR from "fp-ts/lib/Array.js"
import { ErrorContext } from "./Error"
import * as S from 'fp-ts/string'
import * as N from 'fp-ts/number'
import * as OR from 'fp-ts/Ord'

export type ScoresProps = {
  readonly credentials: O.Option<string>,
}

const url = new URL("https://firestore.googleapis.com/v1/projects/mw-tradingbot/databases/(default)/documents/afternoon-v0a_score_results")

type ScoreValue = {
  readonly doubleValue: number
}

type Document = {
  readonly name: string
  readonly fields: Record<string, ScoreValue>
}

type DatabaseScores = {
  readonly documents: Array<Document>
}

export type ScoreProps = {
  readonly score: number
}
export const Score: React.FC<ScoreProps> =
  ({ score }) => {
    const faulty = score == -100
    const positive = score > 0
    const coloration = !faulty ? positive ? "text-green-300" : "text-red-300" : "text-gray-100 line-through"
    return (<span className={coloration}>{score.toPrecision(3)}</span>)
  }


const caseInsensitiveFilter = (filter: string) => ([tickerId,]: [string, any]): boolean => filter!! ? tickerId.toLowerCase().startsWith(filter.toLowerCase()) : true

export type CardProps = {
  readonly fields: Record<string, ScoreValue>
  readonly filter: string
}

const byScore = pipe(
  N.Ord,
  OR.contramap(([, { doubleValue }]: [any, { doubleValue: number }]) => doubleValue),
  OR.reverse
)

export const Card: React.FC<CardProps> =
  ({ fields, filter }) => pipe(
    fields,
    R.toArray,
    AR.filter(caseInsensitiveFilter(filter)),
    AR.sort(byScore),
    AR.map(([tickerId, { doubleValue }]) =>
    (
      <li key={tickerId} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-slate-800 text-gray-100 shadow">
        <div className="flex flex-col items-center justify-between p-4">
          <div className="flex-1 truncate">
            <h3 className="truncate text-sm font-bold text-fuchsia-400">{tickerId.substring(0, tickerId.length - 3)}</h3>
          </div>
          <div><Score score={doubleValue} />
          </div>
        </div>
      </li>)
    ),
    listItems => (<>{listItems}</>)
  )


export type DocumentProps = {
  readonly documents: Array<Document>
  readonly filter: string
}

const byName = pipe(
  S.Ord,
  OR.contramap((obj: Document) => obj.name),
  OR.reverse
)


export const Documents: React.FC<DocumentProps> =
  ({ documents, filter }) => pipe(
    documents,
    AR.sort(byName),
    AR.map(doc => (
      <div className="p-12" key={doc.name.substring(53)}>
        <h1 className="text-2xl font-extrabold mb-8 text-fuchsia-500">{doc.name.substring(53)}</h1>
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-8">
          <Card fields={doc.fields} filter={filter} />
        </ul>

      </div>
    )),
    cards => (<>{cards}</>)
  )

const handleInput =
  (setFilter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: FormEvent<HTMLInputElement>) => {
      event.preventDefault()
      setFilter(event.target.value)
    }

export const Scores: React.FC<ScoresProps> = ({ credentials }) => {
  const [scores, setScores] = useState<O.Option<DatabaseScores>>(O.none)
  const [filter, setFilter] = useState("")
  const { addError } = useContext(ErrorContext)

  useEffect(() => {
    pipe(
      credentials,
      TE.fromOption(() => new Error("No credentials provided")),
      TE.chain(
        cred => TE.tryCatch(() => fetch(url, {
          headers: {
            Authorization: `Bearer ${cred}`
          } as HeadersInit
        }), (error) => new Error(`Loading scores from database failed: ${error}`)
        )
      ),
      TE.chain(response => TE.tryCatch(() => response.json(), () => new Error("Failed to parse JSON"))),
      TE.fold(error => TE.of(addError(error)), data => TE.of(setScores(O.some(data)))),
      f => f()
    )
  }, [])


  const documentCards = pipe(
    scores,
    O.fold(() => (<>No scores</>), databaseScores => (<Documents documents={databaseScores.documents} filter={filter} />))
  )

  return <>
    <header className="fixed w-full z-10 bg-slate-800">
      <div className="container mx-auto px-4 py-2">
        <div className="relative">
          <input autoFocus className="w-full py-2 pl-10 pr-4 text-white bg-slate-900 rounded-full placeholder:text-gray-400 " type="search" placeholder="Filter on stock ticker" onChange={handleInput(setFilter)} />
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 text-blue-400">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </div>
      </div>
    </header>

    <main className="pt-16">
      {documentCards}
    </main>

  </>
}
