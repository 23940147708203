import { GoogleOAuthProvider } from "@react-oauth/google"
import { StrictMode } from "react"
import * as ReactDOMClient from "react-dom/client"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { App } from "./components/App"
import { Error, ErrorProvider } from "./components/Error"


const rootElement = document.getElementById("root")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOMClient.createRoot(rootElement!)


const clientId =
  "544090185974-pfn60rv3ehulqmd7q355ag1m9nf4g6gs.apps.googleusercontent.com"

root.render(
  <StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <ErrorProvider>
        <Router>
          <Error />
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </Router>
      </ErrorProvider>
    </GoogleOAuthProvider>
  </StrictMode>
)
