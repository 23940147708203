import * as O from "fp-ts/lib/Option.js";
import { ComponentPropsWithoutRef, Dispatch, SetStateAction, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { DidLogoutCredentials } from "./App";

export type ExitProps = {
  readonly setCredentials: Dispatch<SetStateAction<DidLogoutCredentials>>
}
export const Exit: React.FC<ExitProps & ComponentPropsWithoutRef<"div">> =
  ({ setCredentials, }) => {
    const navigate = useNavigate()
    useEffect(() => {
      sessionStorage.clear()
      setCredentials([true, O.none])
    }, [])
    return null
  }
