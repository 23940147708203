import { XCircleIcon } from '@heroicons/react/20/solid'
import { ComponentPropsWithoutRef, createContext, useContext, useState } from 'react'

export type Errors = {
  readonly errors: ReadonlyArray<Error>
  readonly addError: (error: Error) => void
  readonly clearErrors: () => void
}

export const ErrorContext = createContext<Errors>({
  errors: [],
  addError: (error) => console.error("Got error before context is ready: " + error),
  clearErrors: () => { }
});

export const ErrorProvider: React.FC<ComponentPropsWithoutRef<"div">> =
  ({ className = "", children, ...props }) => {
    const [errors, setErrors] = useState<ReadonlyArray<Error>>([])
    const addError = (error: Error) => setErrors([...errors, error])
    const clearErrors = () => { setErrors([]) }

    return (
      <ErrorContext.Provider value={{ errors, addError, clearErrors }}>
        <div className={className} {...props}>
          {children}
        </div>
      </ErrorContext.Provider>
    )
  }

export const Error = () => {
  const { errors, clearErrors } = useContext(ErrorContext)

  return errors.length > 0 ? (
    <div className="z-50 fixed top-4 right-4 rounded-sm bg-red-800 p-4 drop-shadow-xl">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-50" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-bold text-red-100">Felmeddelande</h3>
          <div className="mt-2 text-sm text-red-50">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {errors.map((error, idx) => (
                <li key={idx}>{error.message}</li>
              ))}
            </ul>
            <div className="mt-4">
              <div className="-mx-1 -my-1.5 flex justify-end">
                <button
                  onClick={clearErrors}
                  type="button"
                  className="ml-3 rounded-sm bg-red-800 px-2 py-1.5 text-sm font-bold text-red-50 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-700"
                >
                  Avfärda
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>) : (<></>)
}


