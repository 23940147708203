import { Dispatch, SetStateAction } from "react"
import { Route, Routes } from "react-router-dom"
import { DidLogoutCredentials } from "./App"
import { Exit } from "./Exit"
import { Scores } from "./Scores"
import * as O from "fp-ts/lib/Option.js"

export type AuthorizedAppProps = {
  readonly setCredentials: Dispatch<SetStateAction<DidLogoutCredentials>>
  readonly credentials: O.Option<string>
}

export const Protected: React.FC<AuthorizedAppProps> = ({
  setCredentials,
  credentials
}) => (
  <Routes>
    <Route
      path="exit"
      element={<Exit setCredentials={setCredentials} />}
    />
    <Route
      path="/*"
      element={
        <Routes>
          <Route path="/" element={<Scores credentials={credentials} />} />
        </Routes>
      }
    />
  </Routes>)
