import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AuthStates, DidLogoutCredentials } from "../components/App";
import * as O from "fp-ts/lib/Option.js"
import * as E from "fp-ts/lib/Either.js"
import * as AR from "fp-ts/lib/Array.js"

import { pipe } from "fp-ts/lib/function";
import jwtDecode from "jwt-decode";
import { GoogleJwtPayload } from "../shared";
import { DateTime } from "luxon";
import { ErrorContext } from "../components/Error";

const storeSessionStorage = (credentials: string) => O.some(sessionStorage.setItem("credentials", credentials))
const doNothing = (credentials: string) => O.some(credentials)

const checkExpirations = ([nbf, iat, exp]: [number, number, number]): boolean =>
  pipe([nbf, iat, exp], AR.map(DateTime.fromSeconds), ([nbf, iat, exp]) =>
    pipe(
      DateTime.now(),
      now => [nbf > now, now <= iat, now >= exp],
      ([tooEarly, issuedInFuture, expired]) =>
        tooEarly || issuedInFuture || expired
    )
  )

const tryRetrieveCredentialsSessionStorage =
  (addError: (error: Error) => void, setCredentials: Dispatch<SetStateAction<DidLogoutCredentials>>) =>
    () => pipe(
      O.fromNullable(sessionStorage.getItem("credentials")),
      O.chainFirst(credentials => pipe([false, O.fromNullable(credentials)], setCredentials, _ => O.none))
    )

export const useCredentials = (setAuthState: Dispatch<SetStateAction<AuthStates>>): [DidLogoutCredentials, Dispatch<SetStateAction<DidLogoutCredentials>>] => {
  const [[didLogout, credentials], setCredentials] =
    useState<DidLogoutCredentials>([false, O.none])

  const { addError } = useContext(ErrorContext)

  useEffect(() => {
    pipe(
      credentials,
      O.chainFirst(storeSessionStorage),
      O.fold(
        tryRetrieveCredentialsSessionStorage(addError, setCredentials),
        doNothing
      ),
      O.map(_ => setAuthState(AuthStates.Authenticated)),
      O.getOrElse(() => setAuthState(AuthStates.Initial)),
    )
  }, [didLogout, credentials])

  return [[didLogout, credentials], setCredentials]
}
