import * as O from "fp-ts/lib/Option.js"
import { pipe } from "fp-ts/lib/function"
import { useEffect, useState } from "react"
import { Outlet, Route, Routes, useNavigate } from "react-router-dom"
import { useCredentials } from "../hooks/useCredentials"
import { Authorize } from "./Authorize"
import { Enter } from "./Enter"
import { Protected } from "./Protected"

export enum AuthStates {
  Initial = "/enter",
  Authenticated = "/protected",
  Logout = "/exit",
}

/**
 * Tuple of did logout and credentials JWT-token.
 */
export type DidLogoutCredentials = [boolean, O.Option<string>]

export const App = () => {
  const navigate = useNavigate()
  const [authState, setAuthState] = useState<AuthStates>(AuthStates.Initial)
  const [[didLogout, credentials], setCredentials] = useCredentials(setAuthState)

  useEffect(() => {
    navigate(authState.toString())
  }, [authState])

  return (
    <div id="App">
      <Routes>
        <Route
          path={AuthStates.Authenticated + "/*"}
          element={
            <Protected
              setCredentials={setCredentials}
              credentials={credentials}
            />}
        />
        <Route
          path={AuthStates.Initial}
          element={<Enter didLogout={didLogout} setCredentials={setCredentials} />}
        />
        <Route
          path={AuthStates.Logout}
          element={<Enter didLogout={didLogout} setCredentials={setCredentials} />}
        />
      </Routes >
    </div >
  )
}
